/** @format */

import "./App.css";
//import PathTest from './Components/PathTest';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DigitalLetter from "./DigitalLetter";
function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route
            path="/:AcNumber?/:id?/:preview?/:ac_no?/:part_no?/:sl_no?"
            component={DigitalLetter}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
