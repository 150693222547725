/** @format */

//import logo from './logo.svg';
import "./styles.css";
import "./quill.css";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "./Content";
import VoterAddress from "./VoterAddress";
import VoterInfo from "./VoterInfo";
import Image from "./Image";
import ImageSlider from "./ImageSlider";
import VideoSlider from "./VideoSlider";
import NotFound from "./notfound";
import deleteObj from "./deleteVoter.json";
var currentURL = window.location.href;
var getTenant = currentURL.split(".");
console.log("getTenant1", getTenant);
function DigitalLetter() {
  const { AcNumber } = useParams();
  const { id } = useParams();
  const { preview } = useParams();
  const { part_no } = useParams();
  const { ac_no } = useParams();
  const { sl_no } = useParams();
  const [colour, setColour] = useState({});
  const [letterRows, setLetterRows] = useState([]);
  const [voterObj, setVoterObj] = useState({});
  const [noData, setNoData] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [setting, setSetting] = useState({});
  var preview_data;
  console.log();
  if (preview === undefined) {
    preview_data = "active";
    var postData = {
      party_name: process.env.REACT_APP_PARTY_NAME,
      ac_no: parseInt(AcNumber),
      voter_id: id,
      preview: "active",
    };
  } else {
    if (part_no === undefined && sl_no === undefined) {
      var postData = {
        party_name: process.env.REACT_APP_PARTY_NAME,
        ac_no: parseInt(AcNumber),
        voter_id: id,
        preview: "preview",
      };
    } else {
      var postData = {
        party_name: process.env.REACT_APP_PARTY_NAME,
        ac_no: parseInt(AcNumber),
        select_ac_no: parseInt(ac_no),
        part_no: parseInt(part_no),
        slno: parseInt(sl_no),
        preview: "preview",
      };
    }
  }

  const api_url = process.env.REACT_APP_API_URL;
  const getData = useCallback(() => {
    axios({
      method: "POST",
      url: api_url + "/get/dletter",
      data: postData,
      headers: { tenant: getTenant[1] },
    })
      .then((response) => {
        if (response?.data?.rows?.length === 0) {
          setNoData(true);
          setColour({});
        } else {
          let tmpRows = response.data.rows;
          tmpRows.sort(function (a, b) {
            return a.sort_order > b.sort_order;
          });
          setLetterRows(tmpRows);
          setColour(response.data.colour);
          const obj = response.data.voterObj;
          if (obj) {
            const filterObj = {
              "Sl. No": obj.slno,
              "Voter Name": obj.name_e + "/" + obj.name_l,
              "Father's Name": obj.relation_name_e + "/" + obj.relation_name_l,
              "Age / Gender": obj.age + "/" + obj.sex,
              "EPIC#": obj.voter_id,
              "Part No / Name": obj.part_no + "/" + obj.part_name_e,
              "Polling Station": obj.part_address_e + "/" + obj.part_address_l,
            };
            const deleteData = response.data.setting;
            Object.entries(deleteData).forEach((val) => {
              if (!val[1]) {
                delete filterObj[deleteObj[val[0]]];
              }
            });
            setFilterData(filterObj);
          }
          setVoterObj(response.data.voterObj);
          setSetting(response.data.setting);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      getData();
    });
  }, [getData]);
  const main = {
    backgroundColor: colour.mail_colour,
  };
  return (
    <div className="baseContainer container">
      {noData === false ? (
        <>
          {letterRows.map((letterRow) => {
            return (
              <>
                {/* Build the Header */}
                {letterRow["type"] === "header" &&
                  letterRow["data"].length !== 0 ? (
                  <Image pdata={letterRow} />
                ) : null}
                {/* Build the VoterAddress */}
                <div style={{ paddingTop: "0px", paddingLeft: "7px", paddingRight: "7px", paddingBottom: "0px" }}>
                  {letterRow["type"] === "vaddress" && !!setting.voter_address ? (
                    <VoterAddress voterObj={voterObj} setting={setting} />
                  ) : null}
                  {/* Build the Content, Voter Info, Video and Image Carousels */}
                  <div class="row g-0" style={main}>
                    {/* Build the Text */}
                    {letterRow["type"] === "text" &&
                      letterRow["data"].length !== 0 ? (
                      <Content pdata={letterRow} />

                    ) : null}
                    {/* Build the Videos */}
                    {letterRow["type"] === "video" &&
                      letterRow["data"].length !== 0 ? (
                      <VideoSlider pdata={letterRow} />
                    ) : null}
                    {/* Build the Images */}
                    {letterRow["type"] === "image" &&
                      letterRow["data"].length !== 0 ? (
                      <ImageSlider pdata={letterRow} />
                    ) : null}
                  </div>
                  {/* Build the Voter Info */}
                  {/* Build the Voter Info */}
                  <div className="p-1 p-sm-0 col-md-3 col-xs-12  d-md-none">
                    {letterRow["type"] === "vinfo" && setting.voter_info ? (
                      <VoterInfo voterObj={filterData} />
                    ) : null}
                  </div>
                  {/* Build the Voter Info */}
                  <div className="p-1 p-sm-0 d-none d-md-block dlTableData">
                    {letterRow["type"] === "vinfo" && setting.voter_info ? (
                      <VoterInfo voterObj={filterData} />
                    ) : null}
                  </div>

                  {/* Build the Footer */}
                </div>
                {letterRow["type"] === "footer" &&
                  letterRow["data"].length !== 0 ? (
                  <Image pdata={letterRow} />
                ) : null}
              </>
            );
          })}
        </>
      ) : (
        <NotFound msg="Not Found" />
      )}
    </div>
  );
}

export default DigitalLetter;
