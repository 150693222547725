/** @format */

import React from "react";
import "./styles.css";

const Image = (props) => {
  const imgUrl = props.pdata["data"][0]["col1"]["url"];
  return (
    <div className="row">
      <div className="col">
        <img src={imgUrl} className="img-fluid w-100" alt="" />
      </div>
    </div>
  );
};

export default Image;
