import React from "react";

const notFound = ({ msg }) => {
  return (
    <div className="text-center text-warning my-3">
      <h6>{msg}</h6>
    </div>
  );
};

export default notFound;
