/** @format */

import React from "react";
// import Carousel from "react-bootstrap/Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const ImageSlider = (props) => {
  const content = props.pdata["data"];
  let carouSelItem = null;
  let finalContent = content.filter((item1) => {
    return item1["url"].length > 0;
  });
  let carouSelItems = finalContent.map((item, i) => {
    const imgUrl = item["url"];
    carouSelItem = (
      <img
        className="d-block w-100"
        src={imgUrl}
        alt="..."
        id={"img" + i}
        key={"imgk" + i}
      />
    );
    return (
      <div id={"car" + i} key={"cark" + i}>
        {carouSelItem}
      </div>
    );
  });
  return (
    <div className="p-2 px-sm-2 py-sm-1">
      <div
        id="carouselImg"
        className="carousel carousel-dark slide"
        data-bs-ride="carousel"
        key="carouselKey"
        style={{ marginTop: "0.1px", marginBottom: "0.1px" }}
      >
        <Carousel
          className="carousel-inner"
          id="carInn"
          key="carInnKey"
          showStatus={false}
          autoPlay={finalContent.length > 1 ? true : false}
          showThumbs={false} // Add this line
          showArrows={false}
          swipeable={true}
          emulateTouch={true}
          infiniteLoop={true}
          transitionTime={0}
          showIndicators={finalContent.length > 1 ? true : false}>
          {carouSelItems}
        </Carousel>
      </div>
    </div>
  );
};
export default ImageSlider;
