/** @format */
import React from "react";
import Accordion from "react-bootstrap/Accordion";

const Content = (props) => {
  const content = props.pdata["data"];
  let accItems = content.map((item, i) => {
    let htmlString = item["text"];
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const imgSrcUrls = Array.from(doc.querySelectorAll('img')).map(img => {
      const src = img.getAttribute('src');
      htmlString = htmlString.replace(img.outerHTML, '');
      return src;
    });
    const urls = item["url"];
    const thumbImage =
      urls.length > 0 ? (
        <img
          src={urls}
          className="float-start me-2 img-fluid rounded-3 thumb-img"
          alt="..."
          key={"imagehk" + i}
        />
      ) : null;
    Object.assign(item, {
      urls: imgSrcUrls
    })
    const url = item["urls"];
    const mainImage =
      url.length > 0 ? (
        url?.map((imageUrl, index) => {
          return (
            <img
              src={imageUrl}
              className="me-2 img-fluid rounded-3 thumb-img"
              alt="..."
              key={"imagebk" + index}
              style={{
                float: index % 2 === 0 ? 'left' : 'right',
              }}
            />)
        })
      ) : null;

    return (
      <Accordion.Item
        className="accordion-item accordion-item"
        eventKey={i.toString()}
        key={"itemkey" + i}
        style={{ marginBottom: "0.3px" }}

      >
        <React.Fragment>
          <Accordion.Header
            id={"heading" + i}
            key={"headingk" + i}
            className="accordion-header ql-align-justify"
          > <span>
              {thumbImage}
              <div style={{ textAlign: "justify", lineHeight: "23px" }} dangerouslySetInnerHTML={{ __html: item["title"] }} />
            </span></Accordion.Header>
          <Accordion.Body
            className="accordion-body clearfix pt-0"
            key={"accbodyk" + i}
          >
            {mainImage}
            <div style={{ clear: "both" }} dangerouslySetInnerHTML={{ __html: htmlString }} />
            {thumbImage}
          </Accordion.Body>
        </React.Fragment>
      </Accordion.Item>
    );
  });
  return (
    <div className="accordionContainer">
      <div className="accordion" id="accordionExample">

        <Accordion defaultActiveKey="0">
          {accItems}
        </Accordion>
      </div>
    </div>
  );
};
export default Content;
