import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
var currentURL = window.location.href
var getTenant = currentURL.split('.')
console.log('getTenant1', getTenant)
class Index extends React.Component {
  componentDidMount() {
    const fetchData = async () => {
      const api_url = process.env.REACT_APP_API_URL;
      const response = await axios({
        method: "GET",
        url: api_url + "/getSettings",
        headers: { tenant: getTenant[1] },
      })
      document.title = response.data.data.title
    }
    fetchData()
  }
  render() {
    return (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )
  }
}

ReactDOM.render(
  <Index />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
