/** @format */
import React, { useEffect, useState } from "react";
import "./styles.css";
import Table from 'react-bootstrap/Table';

const VoterInfo = ({ voterObj = {} }) => {
  return (
    <Table className='table dlTable bg-white table-striped border mt-1'>
      <tbody>
        {Object.entries(voterObj).map((item) => {
          return (
            <tr className='voter-cell'>
              <td>{item[0]}</td>
              <td><strong>{item[1]}</strong></td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  );
};
export default VoterInfo;
