/** @format */

import React, { useState } from "react";
import ReactPlayer from "react-player/youtube";
import YouTube from "react-youtube";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
//import DownArrow from './images/downarrow.png';

const VideoSlider = (props) => {
  const [index, setIndex] = useState(null);
  const [interval, setInterval] = useState(5000);

  //Handling on Video Start
  const handleStart = () => {
    setInterval(null);
  };

  //Handling on Video End
  const handleEnded = () => {
    setInterval(5000);
  };

  const content = props.pdata["data"];
  let videoCarouSelItem;
  let finalContent = content.filter((item1) => {
    return item1["url"].length > 0;
  });
  let videoCarouSelItems = finalContent.map((item, i) => {
    videoCarouSelItem = (
      <ReactPlayer
        url={item["url"]}
        width="100%"
        height="100%"
        light={false}
        onStart={handleStart}
        onEnded={handleEnded}
        config={{
          youtube: {
            playerVars: { showinfo: 1, origin: "http://localhost:3000" },
          },
        }}
      />
      //<iframe className="video" src={item['url']} title={i}></iframe>
      //<YouTube videoId="2g811Eo7K8U" />
    );
    return (
      <div
        className="video-slideVideo ratio ratio-25x9"
        id={"vcar" + i}
        key={"vcark" + i} >
        {videoCarouSelItem}
      </div >
    );
  });

  return (
    <div className="p-2 px-sm-2 py-sm-1">
      <div className="video-slideVideor ratio ratio-16x9">
        <Carousel
          className="vcarousel-inner"
          id="vcarInn"
          key="vcarInnKey"
          interval={interval}
          showStatus={false}
          swipeable={true}
          autoPlay={finalContent.length > 1 ? true : false}
          showArrows={false}
          emulateTouch={true}
          infiniteLoop={true}
          transitionTime={0}
          showThumbs={false}
          showIndicators={finalContent.length > 1 ? true : false}
        >
          {videoCarouSelItems}
        </Carousel>
      </div>
    </div>
  );
};
export default VideoSlider;
