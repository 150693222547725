/** @format */
import React, { useState, useEffect } from 'react';
import deleteObj from "./deleteVoter.json"
const VoterAddress = (props) => {
  const [data, setData] = useState({})
  useEffect(() => {
    const obj = JSON.parse(JSON.stringify(props.voterObj))
    const name = [obj.name_e, obj.name_l].filter((item) => !!item === true).join(" / ")
    Object.assign(obj, { name: name, to: "To" })
    if (Object.keys(obj).length !== 0) {
      const deleteData = props.setting
      Object.entries(deleteData).forEach((val) => {
        if (!val[1]) {
          delete obj[deleteObj[val[0]]]
        }
      })
      setData(obj)
    }
  }, [props.voterObj]);
  return (
    <div className="row">
      <div className="col voter">
        <address class='m-0 p-2' style={{ whiteSpace: 'pre-line' }}>
          {!!data.to ? <div>{data.to + ", "}</div> : null}
          <strong>{!!data.name ? <div>{data.name + " , "}</div> : null}</strong>
          {!!data.address_e ? data.address_e + "." : null}
        </address>
      </div>
    </div>
  );
};
export default VoterAddress;
